<template>
  <!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-white">
  <body class="h-full">
  ```
-->
<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <img class="mx-auto h-32 w-auto" src="../assets/logo.png" alt="Your Company">
    <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Inicia sesión</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form class="space-y-6">
      <div>
        <label for="email" class="block text-sm font-medium leading-6 text-gray-900 text-left">Usuario</label>
        <div class="mt-2">
          <input id="email" @input="clearError" name="email" v-model="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-700 sm:text-sm sm:leading-6">
        </div>
      </div>

      <div>
        <div class="flex">
          <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Contraseña</label>
        </div>
        <div class="mt-2">
          <input id="password" @input="clearError" v-model="password" name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-700 sm:text-sm sm:leading-6">
        </div>
      </div>
      <div class="text-right">
        <div class="text-sm">
          <a href="#" @click="$router.push({name: 'restore_password'})" class="font-semibold text-red-700 hover:text-red-600">Olvidé mi contraseña</a>
        </div>
      </div>

      <div>
        <button @click.prevent="login" :disabled="loading" class="flex w-full justify-center rounded-md bg-red-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700 disabled:bg-gray-300">Entrar</button>
      </div>

      <div v-if="error">
        <p class="text-center text-red-500">{{ error }}</p>
      </div>
    </form>
  </div>
</div>

</template>

<script>
export default {
  data () {
    return {
      email: '',
      password: '',
      loading: false,
      error: ''
    }
  },
  methods: {
    login: function() {
      this.clearError()
      
      if (!this.email.length || !this.password.length) {
        this.error = 'Todos los datos son obligatorios'
        return
      }
      
      this.loading = true
      
      this.axios.post('/v1/auth/login', {
        email: this.email,
        password: this.password
      }).then(response => {
        if (response.data.success) {
          const user = response.data.resource
          this.$cookies.set('tk', user.access_token)
          delete user.access_token
          user.id = user._id
          this.$cookies.set('cu', user)
          window.location.reload()
        } else {
          this.loading = false
          // show error
          this.error = 'Usuario o contraseña no válidos'
        }
      }).catch(error => {
        this.loading = false
        
        console.log(error);
        // show error
        this.error = 'Usuario o contraseña no válidos'
      })
    },
    clearError: function() {
      this.error = ''
    }
  }
}
</script>

<style>

</style>