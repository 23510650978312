import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: { name : 'login' }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      anonymous: true
    },
    beforeEnter: (to, from, next) => {
      if (Vue.$cookies.get('tk')) {
        next({ name: 'dashboard' })
      }
      next();
    }
  },
  {
    path: '/restore-password',
    name: 'restore_password',
    component: () => import('../views/RestorePassword.vue'),
    meta: {
      anonymous: true
    }
  },
  {
    path: '/parent/:parentId/student/:studentId/payment',
    name: 'openpay',
    meta: {
      anonymous: true
    },
    component: () => import('../views/OpenPay.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    redirect: (to) => {
      let user = Vue.$cookies.get('cu')
      if (user.is_admin) {
        return { name: 'admin' }
      } else if (user.children && user.children.length) {
        return { name: 'payments' }
      } else {
        return { name: 'events' }
      }
    },
    children: [
      {
        path: 'admin',
        name: 'admin',
        component: () => import('../views/dashboard/Admin.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/dashboard/Users.vue')
      },
      {
        path: 'vouchers',
        name: 'vouchers',
        component: () => import('../views/dashboard/Vouchers.vue')
      },
      {
        path: 'users/:user_id',
        name: 'user',
        component: () => import('../views/dashboard/UserDetail.vue')
      },
      {
        path: 'chats',
        name: 'chats',
        component: () => import('../views/dashboard/Chats.vue')
      },
      {
        path: 'payments',
        name: 'payments',
        component: () => import('../views/dashboard/Payments.vue')
      },
      {
        path: 'events',
        name: 'events',
        component: () => import('../views/dashboard/Calendar.vue')
      },
      {
        path: 'broadcasts',
        name: 'broadcasts',
        component: () => import('../views/dashboard/Broadcasts.vue')
      },
      {
        path: 'broadcast/:broadcast_id',
        name: 'broadcast',
        component: () => import('../views/dashboard/BroadcastDetail.vue')
      },
      {
        path: 'documents',
        name: 'documents',
        component: () => import('../views/dashboard/Documents.vue')
      },
      {
        path: 'document/:document_id',
        name: 'document',
        component: () => import('../views/dashboard/DocumentDetail.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('../views/dashboard/Profile.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  if(!to.matched.some(record => record.meta.anonymous) && !Vue.$cookies.get('tk')) {
    return next({ name: "login", query: { redirect: window.location.origin + to.path }, params: to.params });
  }

  next();
})

export default router
