import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './assets/styles/index.css'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import Moment from 'moment-timezone'
Moment.locale('es')
Moment.tz.setDefault('America/Mexico_City')
Vue.prototype.$moment = Moment

Vue.prototype.$groups = Object.freeze({
  AMB_1: 'Ambientación 1',
  AMB_2: 'Ambientación 2',
  AMB_3: 'Ambientación 3',
  PRIMERO: 'Primero',
  SEGUNDO: 'Segundo',
  TERCERO: 'Tercero',
  CUARTO: 'Cuarto',
  QUINTO: 'Quinto',
  SEXTO: 'Sexto',
  MUSICA: 'Música',
  INGLES: 'Inglés',
  DEPORTES: 'Deportes',
  DIRECCION: 'Dirección',
  MAESTROS: 'Maestros'
})

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios.create({
    baseURL: process.env.VUE_APP_ENV == 'DEV' ? process.env.VUE_APP_BACK_DEV : process.env.VUE_APP_BACK_PROD, 
    headers: {
      'x-auth-user': Vue.$cookies.get('tk')
    }
  })
)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
